.wrapper {
  width: 90%;
  margin: auto;
}

.messenger-btn {
  margin: 0px 20px;
  padding: 10px 25px;
  border-radius: 5px;
  border-width: 0px;
  background-color: #e66177;
  /* background-image: linear-gradient(to right, #e51162, #f441e8); */
  color: #fff;
  :hover {
    opacity: 0.7;
    transition: 0.4s;
    cursor: pointer;
  }
}

.messenger-btn:disabled {
  background-color: #cccccc;
  color: #666666;
}

.messenger-input {
  padding: 10px;
  outline: 0px;
  border-width: 0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  width: 500px;
}

#videoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.messenger-video {
  margin: 30px;
  border: 1px dashed pink;
  width: 50%;
  height: 100%;
}

.messenger-textarea {
  padding: 20px;
  background-color: black;
  border: 1px dotted #e66177;
  color: #fff;
  width: 80%;
  margin: auto;
  height: 150px;
  resize: none;
  box-sizing: border-box;
}

.messenger-table {
  th,
  td {
    text-align: center;
    font-weight: 300;
    color: #fff;
  }
}
