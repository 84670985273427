@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap");

@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "react-image-crop/src/ReactCrop.scss";
@import "antd/dist/antd.css";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0px;
  margin: 0px !important;
  font-weight: 300 !important;
}

button {
  font-size: 14px !important;
  font-weight: 300;
}

p {
  font-size: 14px;
}

.text-primary {
  color: #df4156;
}

.bg-grey {
  background-color: #bfb9b933;
}

.text-main {
  color: pink;
}

.my-title {
  background-color: #bfb9b933;
  padding: 10px 40px;
  color: #fff;
  margin: 20px;
  font-size: 24px;
}

.mybtn {
  padding: 10px 30px;
  border-radius: 5px;
  border-width: 0px;
  color: #fff;
  background-color: #df4156;
  transition: 0.4s;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.desktop-only {
  display: initial;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.mybtn-grey {
  padding: 10px 30px;
  border-radius: 5px;
  border-width: 0px;
  transition: 0.4s;
  color: #fff;
  background-color: #bfb9b933;
  &:hover {
    opacity: 0.7;
  }
}

.cursor-pointer {
  transition: 0.4s;
}
.cursor-pointer:hover {
  opacity: 0.5;
  cursor: pointer;
}

.custom-file-upload {
  background-color: #bfb9b933;
  color: #fff;
  display: inline-block;
  width: 260px;
  height: 442px;
  cursor: pointer;
}
.custom-avatar-upload {
  cursor: pointer;
}
input[type="file"] {
  display: none;
}

.side-scrollbar::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.side-scrollbar::-webkit-scrollbar-track {
  background: #3f3f3f; /* color of the tracking area */
}

.side-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3f3f3f; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid black; /* creates padding around scroll thumb */
}

.comments-scrollbar::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

// .comments-scrollbar::-webkit-scrollbar-track {
//   background: rgba(194, 192, 192, 0.479); /* color of the tracking area */
// }

.comments-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.822); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
